<template>
    <div class="collect">
       <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">产品收藏</div>
       <div class="navHeader flex">
        <div class="search-box flex">          
          <input type="text" placeholder="请输入搜索关键词" v-model="searchVal" @keyup.enter="searchSubmit">
          <img src="@/assets/img/serch.png" @click="searchSubmit">
        </div>
      </div>
        <van-checkbox-group v-model="checked" ref="checkboxGroup" checked-color="#f68223">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
          <div class="card flex" v-for="(item,index) in list" :key="item">
        <van-checkbox :name="item.productId"></van-checkbox>
        <div class="goods">
        <div class="goods-header flex">
          <img :src="store.state.imgURL + item.bankImgUrl">
          <div @click="goLoanContent(item.productId,item.loanedCnt,item.bankImgUrl)" class="flex1">{{item.creditName}}</div>
          <img class="del" src="@/assets/img/del.png" @click="delBtn(item.productId,index)">
        </div>
        <div class="goods-content">
          <img :src="store.state.imgURL + item.imgUrl" alt="">
          <div class="flex-r">
              <!-- <div class="time flex">
                <img src="@/assets/img/time.png">
                <p>2021-09-08 10:20:30</p> 
              </div> -->
              <p>融资额度：{{item.minLimitAmt ? item.minLimitAmt+'~'+item.maxLimitAmt + '万': '无限制'}}</p>
              <p>融资金额期限：{{item.minYears ? item.minYears+'~'+item.maxYears + '个月': '无限制'}}</p>
              <p>融资利率：{{item.minRate ? item.minRate+'%~'+item.maxRate + '%': '优惠利率'}}</p>
              <div class="flex" style="margin-top: 1vw;">
                <div @click="applyRequirement(item.productId)" class="apply">我要申请</div>
                <div class="share" @click="showShare = true">
                <img src="@/assets/img/share.png" alt="">
                <p>分享</p>
                </div>
              </div>                
          </div>
          
        </div>
        </div>
        </div>
        </van-list>
        </van-checkbox-group>
        <div style="height: 12vw"></div>
        <div class="collect-footer">
            <van-checkbox v-model="checkAll" checked-color="#f68223" @click="checkAllChange"><p>全选</p></van-checkbox>
            <div class="delCheck" @click="delCheck">删除所选</div>
        </div>
        
        <van-share-sheet
  v-model:show="showShare"
  title="立即分享给好友"
  :options="shareOptions"
  @select="onSelect"
/>
  <van-popup v-model:show="showQR">
    <QrcodeVue :value="showQRurl" :size="256" style="padding: 5px"></QrcodeVue>
  </van-popup>
    </div>
</template>
<script>
import { ref,reactive,watch } from 'vue';
import { apiCollectList,apiCollect } from '../../api/axios';
import { Toast } from 'vant';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useClipboard from 'vue-clipboard3';
import QrcodeVue from 'qrcode.vue';
export default {
    setup() {
        let store = useStore();
        let router = useRouter();
        const totalPage = ref(0);
        const pageNo = ref(1);
        const checked = ref([]);
        // const list = reactive([{id:1, name: 'a',}, {id:2, name: 'b'},{id:3,name: 'c'},{id:4,name: 'd'}]);
        const list = reactive([]);
        const loading = ref(false);
        const finished = ref(false);
        const checkboxGroup = ref(null);
        const showShare = ref(false);
        const checkAll = ref(false);
        const showQR = ref(false);
        const showQRurl = ref('http://yhapp.gaoliuxu.com/#/');
        const shareOptions = [
        // { name: '微信', icon: 'wechat' },
        // { name: '微博', icon: 'weibo' },
        { name: '复制链接', icon: 'link' },
        { name: '二维码', icon: 'qrcode' },
        ];
        //分享        
        const onSelect = (option) => {
          // console.log(option.name);
          let urls = window.location.href;
          if(option.name==='复制链接') {
            copy(urls);//复制到剪切板
            showShare.value = false;
          }else {
            showShare.value = false;
            showQRurl.value = urls;
            showQR.value = true;        
          }
        };
        const { toClipboard } = useClipboard()
        const copy = async (Msg) => {
          try {
            //复制
            await toClipboard(Msg)
            // console.log(Msg)
            Toast('已复制到剪切板');
          } catch (e) {
            //复制失败
            // console.error(e)
            Toast('复制失败');
          }
        }

        const onLoad = () => {
          apiCollectList({pageNo: pageNo.value}).then(res => {                
             if(res.code == '1'){
                 res.info.collectList.forEach(e => {
                    list.push(e);
                 });                
                if( pageNo.value === 1) {
                  totalPage.value = res.info.page.totalPage;
                  finished.value = false;//加载设置为未完成
                }
                // 数据全部加载完成
                if(pageNo.value >= totalPage.value){
                  finished.value = true;
                }else {
                  pageNo.value++;
                  // 加载状态结束
                  loading.value = false;
                }
              }
              else {
                Toast(res.msg);
              }
          });           
      };
        const checkAllChange = () => {
            if(checkAll.value) {
                checkboxGroup.value.toggleAll(true);
                console.log(checked.value)
            }else {
                checkboxGroup.value.toggleAll(false);
                console.log(checked.value)
            }
        };
        //删除
        const delBtn = (id,index) =>{
          let obj = {
            proId: id.toString(),
            cancelCollectFlag: '1'
          };
          apiCollect(obj).then(res => {
             console.log(res,'删除收藏返回的数据')                   
             if(res.code == '1'){
               list.splice(index, 1);         
              }
              else {
                Toast(res.msg);
              }
          });    
                   
        }
        //删除所选
        const delCheck = () =>{
          for (let i = 0; i < checked.value.length; i++) {
            for (let j = 0; j < list.length; j++) {
              if (checked.value[i] === list[j].productId) {
                // const position = list.indexOf(checked.value[i]);
                let pId = checked.value[i].toString();
                apiCollect({proId: pId,cancelCollectFlag: '1'}).then(res => {
                console.log(res,'删除收藏返回的数据')                   
                if(res.code == '1'){
                    // list.splice(j, 1);
                    console.log('循环遍历删除数组')
                  }
                  else {
                    Toast(res.msg);
                  }
                });  
                 break;               
              }
            }
          }
          router.go(0);    
        }
        //搜索框键入
        const searchVal = ref('');
        const searchSubmit = () => {
          console.log('搜索值为：',searchVal.value);
        };
        
        //监听
        watch(checked,(newChecked, oldChecked) => {
            if(newChecked.length == list.length) {
              checkAll.value = true;
            }else {
              checkAll.value = false;
            }
        });

        // 我要申请
      const applyRequirement = (id) =>{           
          router.push({
            path: '/apply',
            query: {
              proIds: id
            }
          });        
      };
       //产品详情
      const goLoanContent = (id,cnt,url) =>{
        let obj = {
          productId: id.toString(),
          loanedCnt: cnt,
          bankImgUrl: url
        }
        let obj2 = JSON.stringify(obj);
        // console.log(obj2);
        router.push({
          path: '/loanProductContent',
          query: {
            proIds: encodeURIComponent(obj2)
          }
        });        
      };
      const goBack = () =>{
        if(store.state.isiOS) {
          try {
            window.webkit.messageHandlers.back.postMessage(null);
          } catch (e) {
            alert('跳转返回ios失败');
          }
        }else {
            router.push({
                name: 'User'
            })
        }
    }
        return {
            store,
            checked,
            list,
            onLoad,
            loading,
            finished,
            shareOptions,
            onSelect,
            showShare,
            showQR,
            showQRurl,
            checkAll,
            checkAllChange,
            delBtn,
            delCheck,
            checkboxGroup,
            searchVal,
            searchSubmit,
            applyRequirement,
            goLoanContent,
            goBack
       };
    },
    components: {
      QrcodeVue
    }

}
</script>
<style lang="less" scoped>
.collect {
    padding-top: 12.5vw;
    
    .card {
        padding-top:3vw;
    .goods {
        flex: 1;
        margin-left: 4vw;
    .goods-header {
      margin-bottom: 2vw;
      img {
        width: 28.6vw;
        height: auto;
      };
      .flex1 {
        flex: 1;
        margin-left: 2vw;
        font-size: 4.8vw;
        color: #6699ff;
        font-weight: 600;
      }
      img.del {
        width: 4.8vw;
        height: auto;
      }
    }
    .goods-content {
        display: flex;
      img {
        height: 18.7vw;
        width: 28.6vw;
      }
      .flex-r {
          flex: 1;
          margin-left: 2vw;
          p {
            margin: 0;
            font-size: 3.2vw;
            color: #666666;
            margin: 0.5vw;
          }
          .time {
              img {
                  width: 4vw;
                  height: auto;
                  margin-right: 1vw;
              }
              p {
                font-size: 3.5vw;
                color: #999;
                line-height: 4vw;
              }
          }
      }
      .share {
        border: 1px solid #ff9900;
        padding: 0.5vw 3vw;
        border-radius: 4vw;
        display: flex;
        align-items: center;
        margin-left: 2vw;
          img {
            width: 3.6vw;
            height: auto;
            margin-right: 1vw;
          }
          p {
            color: #ff9900;
            font-size: 3.2vw;
          }
      }
      .apply {
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 3.5vw;
        padding: 1vw 2vw;
        border-radius: 4vw;
      }

    }

  }
        
    }
    .collect-footer {
        background-color: #fff;
        width: calc(100% - 8vw);
        padding: 2vw 4vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 4vw;
        position: fixed;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e7e7e7;
        border-bottom: 1px solid #f8f8f8;
        p {
            color: #999;
        }        
        div.delCheck {
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            padding: 2vw 6vw;
            border-radius: 5vw;
        }
    }
}
</style>
